import { useState, createContext, PropsWithChildren, useEffect } from "react"
import { Meme } from "../types/meme"
import { api } from "../lib/api"

export type ContentContextProps = {
  memes: Meme[]
  trendingMemes: Meme[]
  currentMeme: Meme | null
  refreshMemes: () => Promise<any>
  refreshTrendingMemes: () => Promise<any>
  refreshCurrentMeme: (uid?: Meme["uid"]) => Promise<any>
  patchMeme: (
    uid: Meme["uid"],
    store: "memes" | "trendingMemes",
    patch: Partial<Meme>,
    asyncCallback?: (...args: any) => Promise<any>,
  ) => Promise<any>
}

export const ContentContext = createContext<ContentContextProps>({
  memes: [],
  trendingMemes: [],
  currentMeme: null,
  refreshMemes: async () => {},
  refreshTrendingMemes: async () => {},
  refreshCurrentMeme: async () => {},
  patchMeme: async () => {},
})

export const ContentProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [memes, setMemes] = useState<Meme[]>([])
  const [trendingMemes, setTrendingMemes] = useState<Meme[]>([])
  const [currentMeme, setCurrentMeme] = useState<Meme | null>(null)

  const refreshMemes: ContentContextProps["refreshMemes"] = async () => {
    try {
      const response = await api.fetchMemes()
      setMemes(response.data)
    } catch (error) {
      console.error(error)
    }
  }

  const refreshTrendingMemes: ContentContextProps["refreshTrendingMemes"] =
    async () => {
      try {
        const response = await api.fetchTrendingMemes()
        setTrendingMemes(response.data)
      } catch (error) {
        console.error(error)
      }
    }

  const refreshCurrentMeme: ContentContextProps["refreshCurrentMeme"] =
    async uid => {
      if (!uid && !currentMeme?.uid) return

      try {
        const response = await api.fetchMeme(uid || currentMeme?.uid || "")
        setCurrentMeme(response.data)
      } catch (error) {
        console.error(error)
      }
    }

  const patchMeme: ContentContextProps["patchMeme"] = async (
    uid,
    store,
    patch,
    asyncCallback,
  ) => {
    const currentMeme = memes.find(meme => meme.uid === uid)
    const nextMeme = {
      ...currentMeme,
      ...patch,
    }
    const nextMemes = memes.map(meme => {
      if (meme.uid === uid) {
        return nextMeme
      }

      return meme
    })

    let callbackResult
    if (store === "memes") {
      setMemes(nextMemes as Meme[])
      if (asyncCallback) {
        callbackResult = await asyncCallback()
      }
      refreshMemes()
    } else if (store === "trendingMemes") {
      setTrendingMemes(nextMemes as Meme[])
      if (asyncCallback) {
        callbackResult = await asyncCallback()
      }
      refreshCurrentMeme()
    }

    return callbackResult
  }

  useEffect(() => {
    if (memes.length === 0 || trendingMemes.length > 0) return
    refreshTrendingMemes()
  }, [memes, trendingMemes.length])

  return (
    <ContentContext.Provider
      value={{
        memes,
        trendingMemes,
        currentMeme,
        refreshMemes,
        refreshCurrentMeme,
        refreshTrendingMemes,
        patchMeme,
      }}
    >
      {children}
    </ContentContext.Provider>
  )
}
