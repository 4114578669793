import cn from "classnames"

import { Meme } from "../../../types/meme"
import styles from "./MemeCard.module.scss"

import AvatarPlaceholder from "../../../assets/avatar_placeholder.png"
import { Link, useLocation } from "react-router-dom"
import { useContext, useState } from "react"
import ShareModal from "../ShareModal/ShareModal"
import { UserContext } from "../../../context/UserContext"
import { Heart } from "../../icons/Heart"
import { DollarSign } from "../../icons/DollarSign"
import { durationFromTimestamp } from "../../../lib/duration"
import { Comment } from "../../icons/Comment"
import { Save } from "../../icons/Save"
import { Share } from "../../icons/Share"
import { ContentContext } from "../../../context/ContentContext"
import { Avatar } from "../../icons/Avatar"
import { api } from "../../../lib/api"
import { getPriceLevel } from "../../../lib/price"

export type MemeCardProps = {
  className?: string
  meme: Meme
}

export const MemeCard: React.FC<MemeCardProps> = props => {
  const { memes, patchMeme } = useContext(ContentContext)
  const { className, meme } = props
  const [isModalOpen, setIsModalOpen] = useState(false)
  const location = useLocation()
  const isTrendingPage = location.pathname === "/trending"
  const { user } = useContext(UserContext)
  const author = meme.author.email.split("@")[0]
  const priceLevel = getPriceLevel(memes, meme.price) || 0

  const handleLike = async (meme: Meme) => {
    if (!user) return
    const action = meme.hasLiked ? "dislike" : "like"
    const patch: Partial<Meme> = {
      hasLiked: action === "like",
      hasDisliked: action === "dislike",
      nrOfLikes: meme.hasLiked ? meme.nrOfLikes - 1 : meme.nrOfLikes + 1,
    }

    await patchMeme(
      meme.uid,
      isTrendingPage ? "trendingMemes" : "memes",
      patch,
      async () => {
        if (user) return api.likeMeme(meme.uid, action)
        return null
      },
    )
  }

  const handleComment = () => {}

  const handleSave = () => {}

  const handleShare = () => {
    setIsModalOpen(true)
  }

  return (
    <>
      <div className={cn([className, styles.card])}>
        <div className={styles.header}>
          <div className={styles.metadata}>
            <div className={styles.author}>
              <div className={styles.username}>
                <Avatar width={24} height={24} imageUrl={AvatarPlaceholder} />
                <div className={styles.authorUsername}>{author}</div>
                <div className={styles.dot}>•</div>
                <div className={styles.duration}>
                  {durationFromTimestamp(meme.createdAt)}
                </div>
              </div>
            </div>
            <div className={styles.priceLevel}>
              <DollarSign
                height={15}
                color={priceLevel >= 0 ? "#FFFFFF" : "#808080"}
              />
              <DollarSign
                height={15}
                color={priceLevel >= 1 ? "#FFFFFF" : "#808080"}
              />
              <DollarSign
                height={15}
                color={priceLevel >= 2 ? "#FFFFFF" : "#808080"}
              />
            </div>
          </div>
          <div className={styles.title}>{meme.title}</div>
        </div>
        <div className={styles.imageContainer}>
          <Link to={`/gallery/${meme.uid}`}>
            <img className={styles.image} src={meme.image} alt={meme.title} />
          </Link>
        </div>
        <div className={styles.actions}>
          <div className={styles.icons}>
            <div
              className={styles.iconContainer}
              onClick={() => handleLike(meme)}
            >
              <Heart
                color={meme.hasLiked ? "filled" : "#A1A1A1"}
                width={24}
                height={24}
              />
              <div className={styles.count}>{meme.nrOfLikes}</div>
            </div>
            <div className={styles.iconContainer} onClick={handleComment}>
              <Comment color="#A1A1A1" width={24} height={24} />
              <div className={styles.count}>0</div>
            </div>
            <div className={styles.iconContainer} onClick={handleSave}>
              <Save
                color={false ? "filled" : "#A1A1A1"}
                width={24}
                height={24}
              />
              <div className={styles.count}>0</div>
            </div>
          </div>
          <div className={cn(styles.icons, styles.right)} onClick={handleShare}>
            <div className={styles.iconContainer}>
              <Share color="#A1A1A1" width={24} height={24} />
            </div>
          </div>
        </div>
      </div>
      {isModalOpen && (
        <ShareModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          title="Share"
          memeUid={meme.uid}
        />
      )}
    </>
  )
}
