export const AddMeme: React.FC<{
  width?: string
  height: string
  color?: string
  className?: string
}> = ({ width, height, className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={className}
      viewBox="0 0 32 33"
      fill="none"
    >
      <circle cx="16" cy="16.9111" r="16" fill="url(#paint0_linear_91_1549)" />
      <path
        d="M16 10.054V23.7682"
        stroke="white"
        strokeWidth="2.05714"
        strokeLinecap="round"
      />
      <path
        d="M9.14258 16.9109L22.8569 16.9109"
        stroke="white"
        strokeWidth="2.05714"
        strokeLinecap="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_91_1549"
          x1="16"
          y1="0.911133"
          x2="16"
          y2="32.9111"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A031E3" />
          <stop offset="1" stopColor="#D400B4" />
        </linearGradient>
      </defs>
    </svg>
  )
}
