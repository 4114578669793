export const Notifications: React.FC<{
  width?: string
  height: string
  color?: string
  className?: string
}> = ({ width, height, className, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={className}
      viewBox="0 0 33 33"
      fill="none"
    >
      <path
        d="M28.8136 22.056C28.7099 21.931 28.608 21.806 28.508 21.6854C27.133 20.0222 26.3011 19.0185 26.3011 14.3104C26.3011 11.8729 25.718 9.87287 24.5686 8.37287C23.7211 7.26475 22.5755 6.42412 21.0655 5.80287C21.0461 5.79206 21.0287 5.77788 21.0142 5.761C20.4711 3.94225 18.9849 2.72412 17.3086 2.72412C15.6324 2.72412 14.1467 3.94225 13.6036 5.75912C13.5891 5.77539 13.572 5.78912 13.553 5.79975C10.0292 7.25037 8.31674 10.0335 8.31674 14.3085C8.31674 19.0185 7.48612 20.0222 6.10987 21.6835C6.00987 21.8041 5.90799 21.9266 5.80424 22.0541C5.53624 22.3773 5.36644 22.7705 5.31494 23.1872C5.26343 23.6039 5.33238 24.0266 5.51362 24.4054C5.89924 25.2179 6.72112 25.7222 7.65924 25.7222H26.9649C27.8986 25.7222 28.7149 25.2185 29.1017 24.4097C29.2838 24.0309 29.3534 23.6079 29.3023 23.1907C29.2512 22.7735 29.0816 22.3797 28.8136 22.056ZM17.3086 30.7241C18.2118 30.7234 19.0979 30.4782 19.873 30.0147C20.6481 29.5511 21.2832 28.8864 21.7111 28.091C21.7313 28.0529 21.7412 28.0102 21.74 27.9671C21.7388 27.924 21.7265 27.8819 21.7042 27.845C21.6819 27.8081 21.6505 27.7776 21.6129 27.7564C21.5754 27.7352 21.533 27.7241 21.4899 27.7241H13.1286C13.0854 27.724 13.043 27.735 13.0053 27.7561C12.9677 27.7773 12.9362 27.8078 12.9138 27.8447C12.8915 27.8817 12.8791 27.9238 12.8778 27.9669C12.8766 28.0101 12.8866 28.0528 12.9067 28.091C13.3346 28.8863 13.9696 29.5509 14.7446 30.0145C15.5196 30.4781 16.4056 30.7233 17.3086 30.7241Z"
        fill={color}
      />
    </svg>
  )
}
