export const DollarSign: React.FC<{
  color?: string
  width?: string | number
  height: string | number
  className?: string
}> = ({ color, width, height, className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={className}
      viewBox="0 0 9 15"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.46656 8.4086V11.9444C6.71078 11.8326 7.23442 11.1357 7.23442 10.2154C7.23442 9.32896 6.8271 8.8046 5.46656 8.4086ZM4.40584 6.64071V3.45851C3.2486 3.60524 2.63798 4.28234 2.63798 5.02486C2.63798 5.76737 3.12025 6.31435 4.40584 6.64071ZM5.40998 6.90129L5.78901 6.99924C7.86519 7.49743 9.00228 8.37925 9.00228 10.1185C9.00228 12.119 7.42322 13.254 5.40998 13.4011V14.773H4.51792V13.4011C2.51246 13.2459 0.925628 12.0784 0.870117 10.1185H2.50433C2.64646 11.0739 3.24683 11.7761 4.51792 11.9147V8.35521L4.19405 8.27353C2.18859 7.7916 1.10701 6.86063 1.10701 5.20306C1.10701 3.374 2.52837 2.16547 4.51792 2.01025V0.629883H5.40998V2.0099C7.38362 2.17325 8.81276 3.39804 8.87605 5.25185H7.23371C7.13117 4.22294 6.42049 3.62681 5.40998 3.49599V6.90129Z"
        fill={color}
      />
    </svg>
  )
}
