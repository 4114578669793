export const Heart: React.FC<{
  color?: string
  width?: string | number
  height: string | number
  className?: string
}> = ({ color, width, height, className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={className}
      viewBox="0 0 25 25"
      fill="none"
    >
      <g clipPath="url(#clip0_91_1470)">
        <path
          d="M12.3842 22.0221C12.2351 22.0217 12.0903 21.9718 11.9725 21.8804C8.21946 18.9643 5.63421 16.4533 3.8252 13.976C1.5167 10.8102 0.990199 7.88739 2.2592 5.28859C3.1637 3.43231 5.76246 1.91353 8.79997 2.79779C10.2482 3.21612 11.5118 4.11325 12.3842 5.34259C13.2567 4.11325 14.5202 3.21612 15.9685 2.79779C18.9992 1.92703 21.6047 3.43231 22.5092 5.28859C23.7782 7.88739 23.2517 10.8102 20.9432 13.976C19.1342 16.4533 16.549 18.9643 12.796 21.8804C12.6782 21.9718 12.5334 22.0217 12.3842 22.0221Z"
          fill={color === "filled" ? "url(#paint0_linear_91_1470)" : color}
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_91_1470"
          x1="12.3842"
          y1="2.53906"
          x2="12.3842"
          y2="22.0221"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A031E3" />
          <stop offset="1" stopColor="#D400B4" />
        </linearGradient>
        <clipPath id="clip0_91_1470">
          <rect
            width="24.3001"
            height="24.3004"
            fill="white"
            transform="translate(0.207031 0.133301)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
