export const Dictate: React.FC<{
  color?: string
  width?: string | number
  height: string | number
  className?: string
}> = ({ color, width, height, className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={className}
      viewBox="0 0 19 19"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.99912 0.35498V0.35498C10.5069 0.35498 11.7291 1.57726 11.7291 3.08501V9.45508C11.7291 10.9628 10.5069 12.1851 8.99912 12.1851V12.1851C7.49137 12.1851 6.26909 10.9628 6.26909 9.45508V3.08501C6.26909 1.57726 7.49137 0.35498 8.99912 0.35498ZM13.7767 6.27004C13.3997 6.27004 13.0942 6.57561 13.0942 6.95255V9.45508C13.0942 11.7167 11.2608 13.5501 8.99912 13.5501C6.73749 13.5501 4.90408 11.7167 4.90408 9.45508V6.95255C4.90408 6.57561 4.59851 6.27004 4.22157 6.27004C3.84463 6.27004 3.53906 6.57561 3.53906 6.95255V9.45508C3.54041 12.2057 5.5876 14.5258 8.31661 14.8696V16.2802H6.49659C6.11966 16.2802 5.81409 16.5857 5.81409 16.9627C5.81409 17.3396 6.11966 17.6452 6.49659 17.6452H11.5016C11.8786 17.6452 12.1842 17.3396 12.1842 16.9627C12.1842 16.5857 11.8786 16.2802 11.5016 16.2802H9.68163V14.8696C12.4106 14.5258 14.4578 12.2057 14.4592 9.45508V6.95255C14.4592 6.57561 14.1536 6.27004 13.7767 6.27004V6.27004Z"
        fill={color}
      />
    </svg>
  )
}
