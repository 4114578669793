import AvatarPlaceholder from "../../assets/avatar_placeholder.png"

export const Avatar: React.FC<{
  width?: number
  height: number
  color?: string
  className?: string
  imageUrl?: string
}> = ({ width, height, className, color, imageUrl }) => {
  const avatarStyle = {
    backgroundColor: color,
    borderRadius: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  }

  const imageStyle = {
    width: width ? width - 2 : 30,
    height: height ? height - 2 : 30,
    border: `1px solid ${color}`,
    borderRadius: "100%",
  }

  return (
    <div style={avatarStyle} className={className}>
      <img
        src={imageUrl || AvatarPlaceholder}
        style={imageStyle}
        alt="avatar"
      />
    </div>
  )
}
