import { Meme } from "../types/meme"

export const getTopPrice = (memes: Meme[]) =>
  Math.max(...memes.map(meme => meme.price))

export const getPriceLevels = (topPrice: number) => [0, topPrice / 3, topPrice]

export const getPriceLevel = (memes: Meme[], price: number) => {
  let priceLevelIndex = 0
  const topPrice = getTopPrice(memes)
  const priceLevels = getPriceLevels(topPrice)

  if (price >= priceLevels[1]) {
    priceLevelIndex = 1
  }

  if (price >= priceLevels[2] - priceLevels[1]) {
    priceLevelIndex = 2
  }

  return priceLevelIndex
}
