export const Home: React.FC<{
  color?: string
  width?: string
  height: string
  className?: string
}> = ({ color, width, height, className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={className}
      viewBox="0 0 32 32"
      fill="none"
    >
      <path
        d="M16.3469 6.32985C16.2539 6.24084 16.1301 6.19116 16.0013 6.19116C15.8725 6.19116 15.7487 6.24084 15.6557 6.32985L4.14941 17.3217C4.10055 17.3685 4.06167 17.4246 4.03514 17.4868C4.0086 17.549 3.99496 17.616 3.99504 17.6836L3.99316 27.9999C3.99316 28.5303 4.20388 29.039 4.57895 29.4141C4.95402 29.7891 5.46273 29.9999 5.99316 29.9999H11.9994C12.2646 29.9999 12.519 29.8945 12.7065 29.707C12.8941 29.5194 12.9994 29.2651 12.9994 28.9999V20.4999C12.9994 20.3672 13.0521 20.2401 13.1459 20.1463C13.2396 20.0525 13.3668 19.9999 13.4994 19.9999H18.4994C18.632 19.9999 18.7592 20.0525 18.853 20.1463C18.9467 20.2401 18.9994 20.3672 18.9994 20.4999V28.9999C18.9994 29.2651 19.1048 29.5194 19.2923 29.707C19.4798 29.8945 19.7342 29.9999 19.9994 29.9999H26.0032C26.5336 29.9999 27.0423 29.7891 27.4174 29.4141C27.7924 29.039 28.0032 28.5303 28.0032 27.9999V17.6836C28.0032 17.616 27.9896 17.549 27.9631 17.4868C27.9365 17.4246 27.8977 17.3685 27.8488 17.3217L16.3469 6.32985Z"
        fill={color}
      />
      <path
        d="M30.6819 15.2594L26.0069 10.7869V4C26.0069 3.73478 25.9015 3.48043 25.714 3.29289C25.5265 3.10536 25.2721 3 25.0069 3H22.0069C21.7417 3 21.4873 3.10536 21.2998 3.29289C21.1123 3.48043 21.0069 3.73478 21.0069 4V6L17.3869 2.53875C17.0481 2.19625 16.5444 2 16 2C15.4575 2 14.955 2.19625 14.6163 2.53938L1.32252 15.2581C0.933774 15.6331 0.885024 16.25 1.23877 16.6562C1.32761 16.7588 1.43637 16.8422 1.55846 16.9014C1.68055 16.9605 1.8134 16.9942 1.94892 17.0004C2.08445 17.0066 2.21982 16.9852 2.3468 16.9374C2.47378 16.8896 2.5897 16.8165 2.68752 16.7225L15.6563 4.33C15.7493 4.24099 15.8731 4.19131 16.0019 4.19131C16.1307 4.19131 16.2545 4.24099 16.3475 4.33L29.3175 16.7225C29.5086 16.9057 29.7645 17.0057 30.0291 17.0006C30.2938 16.9954 30.5456 16.8855 30.7294 16.695C31.1131 16.2975 31.0813 15.6412 30.6819 15.2594Z"
        fill={color}
      />
    </svg>
  )
}
