export const Store: React.FC<{
  color?: string
  width?: string
  height: string
  className?: string
}> = ({ color, width, height, className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={className}
      viewBox="0 0 32 32"
      fill="none"
    >
      <path
        d="M16 18.2857C13.9372 18.2857 11.9589 17.483 10.5003 16.0541C9.04166 14.6253 8.22222 12.6874 8.22222 10.6667H11.3333C11.3333 11.8791 11.825 13.0418 12.7002 13.8992C13.5753 14.7565 14.7623 15.2381 16 15.2381C17.2377 15.2381 18.4247 14.7565 19.2998 13.8992C20.175 13.0418 20.6667 11.8791 20.6667 10.6667H23.7778C23.7778 12.6874 22.9583 14.6253 21.4997 16.0541C20.0411 17.483 18.0628 18.2857 16 18.2857ZM16 3.04762C17.2377 3.04762 18.4247 3.52925 19.2998 4.38656C20.175 5.24387 20.6667 6.40663 20.6667 7.61905H11.3333C11.3333 6.40663 11.825 5.24387 12.7002 4.38656C13.5753 3.52925 14.7623 3.04762 16 3.04762ZM26.8889 7.61905H23.7778C23.7778 6.6185 23.5766 5.62775 23.1857 4.70336C22.7949 3.77898 22.222 2.93906 21.4997 2.23157C20.7775 1.52407 19.9201 0.962858 18.9764 0.579966C18.0328 0.197073 17.0214 0 16 0C13.9372 0 11.9589 0.802719 10.5003 2.23157C9.04166 3.66042 8.22222 5.59835 8.22222 7.61905H5.11111C3.38444 7.61905 2 8.97524 2 10.6667V28.9524C2 29.7607 2.32778 30.5358 2.91122 31.1074C3.49467 31.6789 4.28599 32 5.11111 32H26.8889C27.714 32 28.5053 31.6789 29.0888 31.1074C29.6722 30.5358 30 29.7607 30 28.9524V10.6667C30 9.85839 29.6722 9.08321 29.0888 8.51167C28.5053 7.94014 27.714 7.61905 26.8889 7.61905Z"
        fill={color}
      />
    </svg>
  )
}
