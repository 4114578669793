export const Comment: React.FC<{
  color?: string
  secondaryColor?: string
  width?: string | number
  height: string | number
  className?: string
}> = ({ color, width, height, className, secondaryColor }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={className}
      viewBox="0 0 25 25"
      fill="none"
    >
      <path
        d="M12.7454 21.4168C14.7329 21.4163 16.6657 20.7661 18.2494 19.5652C19.833 18.3643 20.9807 16.6786 21.5174 14.7649C22.0541 12.8512 21.9504 10.8146 21.2222 8.96529C20.494 7.11599 19.1812 5.55547 17.4839 4.52154C15.7865 3.48761 13.7976 3.03697 11.8204 3.23828C9.84312 3.4396 7.9859 4.28184 6.53173 5.63664C5.07756 6.99145 4.10619 8.78454 3.76564 10.7426C3.42509 12.7008 3.73404 14.7165 4.64541 16.4828L3.6329 21.4168L8.56683 20.4042C9.81828 21.0512 11.2398 21.4168 12.7454 21.4168Z"
        fill={color}
        stroke={color}
        strokeWidth="1.51877"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.21484 12.3049H8.22497V12.3151H8.21484V12.3049ZM12.7711 12.3049H12.7812V12.3151H12.7711V12.3049ZM17.3274 12.3049H17.3375V12.3151H17.3274V12.3049Z"
        fill={secondaryColor || "#070606"}
        stroke={secondaryColor || "#070606"}
        strokeWidth="2.27815"
        strokeLinejoin="round"
      />
    </svg>
  )
}
