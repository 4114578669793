import { Link, useNavigate } from "react-router-dom"
import { firebase } from "../../../lib/firebase"
import { Button } from "../../common/Button/Button"
import { Input } from "../../common/Input/Input"
import styles from "./Login.module.scss"
import { useState } from "react"
import { useForm } from "react-hook-form"

interface LoginFormFields {
  email: string
  password: string
}

export const LoginForm: React.FC = () => {
  const navigate = useNavigate()
  const [generalErrorMessage, setGeneralErrorMessage] = useState<string>("")
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginFormFields>({
    mode: "onChange",
  })

  const submitHandler = async (
    data: LoginFormFields,
    event?: React.BaseSyntheticEvent,
  ) => {
    event?.preventDefault()

    const submitter = (event?.nativeEvent as SubmitEvent)
      .submitter as HTMLButtonElement | null
    const submitterId = submitter?.id

    try {
      if (submitterId === "loginSubmit") {
        await firebase.login({ email: data.email, password: data.password })
        navigate("/")
      } else if (submitterId === "registerSubmit") {
        navigate("/signup")
      }
    } catch (error: any) {
      console.error(error)
      setGeneralErrorMessage("Login failed; Invalid email or password.")
    }
  }

  return (
    <form className={styles.form} onSubmit={handleSubmit(submitHandler)}>
      <label className={styles.greeting}>Welcome to Mema</label>

      {generalErrorMessage && (
        <div className={styles.errorMessageContainer}>
          <p className={styles.errorMessageText}>{generalErrorMessage}</p>
        </div>
      )}

      <div className={styles.loginFields}>
        <Input
          type="text"
          label="Email"
          placeholder="Enter your email"
          {...register("email", {
            required: "Email is required",
            pattern: {
              value: /^[^@]+@[^@]+\.[a-zA-Z]{2,}$/,
              message: "Invalid email format",
            },
          })}
          error={errors.email?.message}
        />
      </div>

      <div className={styles.loginFields}>
        <Input
          type="password"
          label="Password"
          placeholder="Enter your password"
          {...register("password", {
            required: "Password is required",
            minLength: {
              value: 6,
              message: "Password must be at least 6 characters",
            },
          })}
          error={errors.password?.message}
        />
      </div>

      <div>
        <Button
          className={styles.button1}
          text="Sign in"
          type="primary"
          htmlType="submit"
          id="loginSubmit"
        />
      </div>
      <p className={styles.note}>
        Don't have an account? Please{" "}
        <Link className={styles.link} to="/signup">
          sign up
        </Link>
        .
      </p>
    </form>
  )
}
