export const Save: React.FC<{
  color?: string
  width?: string | number
  height: string | number
  className?: string
}> = ({ color, width, height, className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={className}
      viewBox="0 0 26 25"
      fill="none"
    >
      <path
        d="M18.211 3.2832H7.71094C7.31312 3.2832 6.93158 3.43492 6.65028 3.70499C6.36897 3.97505 6.21094 4.34133 6.21094 4.72326V20.5639C6.211 20.6924 6.24689 20.8185 6.31487 20.9292C6.38285 21.0399 6.48044 21.1311 6.59753 21.1934C6.71461 21.2556 6.84692 21.2867 6.98072 21.2833C7.11452 21.2799 7.24494 21.2422 7.35844 21.1741L12.961 17.8125L18.5644 21.1741C18.6779 21.242 18.8082 21.2795 18.9419 21.2827C19.0755 21.286 19.2077 21.2549 19.3246 21.1927C19.4415 21.1304 19.539 21.0393 19.607 20.9288C19.6749 20.8182 19.7108 20.6922 19.711 20.5639V4.72326C19.711 4.34133 19.5529 3.97505 19.2716 3.70499C18.9903 3.43492 18.6088 3.2832 18.211 3.2832Z"
        fill={color === "filled" ? "url(#paint0_linear_91_1437)" : color}
        stroke={color === "filled" ? "url(#paint0_linear_91_1437)" : color}
        strokeWidth="1.62002"
      />
      <defs>
        <linearGradient
          id="paint0_linear_91_1437"
          x1="12.961"
          y1="3.2832"
          x2="12.961"
          y2="21.2835"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A031E3" />
          <stop offset="1" stopColor="#D400B4" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_91_1437"
          x1="12.961"
          y1="3.2832"
          x2="12.961"
          y2="21.2835"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A031E3" />
          <stop offset="1" stopColor="#D400B4" />
        </linearGradient>
      </defs>
    </svg>
  )
}
