import { Logo } from "../../icons/Logo"
import { MenuIcon } from "../MenuIcon/MenuIcon"
import { SearchInput, SearchInputProps } from "../SearchInput/SearchInput"
import AvatarPlaceholder from "../../../assets/avatar_placeholder.png"
import styles from "./TopBar.module.scss"
import { useMediaQuery } from "usehooks-ts"
import { useNavigate } from "react-router-dom"

export const TopBar: React.FC = () => {
  const displayMenuIcons = useMediaQuery("screen and (max-width: 960px)")
  const hideSearchBar = useMediaQuery("screen and (max-width: 740px)")
  const navigate = useNavigate()

  const handleSearch: SearchInputProps["onSearch"] = searchString => {
    console.log("Would handle search:", searchString)
  }

  const handleDictate: SearchInputProps["onDictate"] = setSearchString => {
    console.log("Would handle dictation")
    setSearchString("Test")
  }

  const handleLogoClick = () => navigate("/")

  return (
    <div className={styles.wrapper}>
      <div className={styles.logoWrapper} onClick={handleLogoClick}>
        <Logo color="#ffffff" height="100%" className={styles.logo} />
      </div>
      {!hideSearchBar && (
        <div className={styles.search}>
          <SearchInput onSearch={handleSearch} onDictate={handleDictate} />
        </div>
      )}
      <div className={styles.icons}>
        {displayMenuIcons && (
          <>
            <MenuIcon.Trending />
            <MenuIcon.Store />
            <MenuIcon.AddMeme />
          </>
        )}
        <MenuIcon.Checkout label={false} />
        <MenuIcon.Notifications label={false} />
        <MenuIcon.Avatar label={false} imageUrl={AvatarPlaceholder} />
      </div>
    </div>
  )
}
