import React from "react"
import { Route, Routes } from "react-router-dom"
import { UserProvider } from "./context/UserContext"
import { FeedPage } from "./pages/Feed/Feed"
import { LoginPage } from "./pages/Login/Login"
import { ProfilePage } from "./pages/Profile/Profile"
import { SignupPage } from "./pages/Signup/Signup"
import { UploadPage } from "./pages/Upload/Upload"
import { MarketplacePostPage } from "./pages/MarketplacePost/MarketplacePost"
import { SettingsPage } from "./pages/Settings/Settings"
import { CheckoutPage } from "./pages/Checkout/Checkout"
import { ReturnPage } from "./pages/CheckoutReturn/CheckoutReturnPage"
import { EditMemePage } from "./pages/EditMeme/EditMeme"
import { SkeletonTheme } from "react-loading-skeleton"
import { ContentProvider } from "./context/ContentContext"

export const App: React.FC = () => {
  return (
    <SkeletonTheme baseColor="#dacce8">
      <UserProvider>
        <ContentProvider>
          <Routes>
            <Route path="/signup" element={<SignupPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/" element={<FeedPage store="memes" />} />
            <Route path="/profile" element={<ProfilePage />} />
            <Route
              path="/trending"
              element={<FeedPage store="trendingMemes" />}
            />
            <Route path="/upload" element={<UploadPage />} />
            <Route path="/gallery/:imageid" element={<MarketplacePostPage />} />
            <Route path="/settings" element={<SettingsPage />} />
            <Route path="/checkout" element={<CheckoutPage />} />
            <Route path="/return" element={<ReturnPage />} />
            <Route path="/gallery/:imageid/edit" element={<EditMemePage />} />
          </Routes>
        </ContentProvider>
      </UserProvider>
    </SkeletonTheme>
  )
}
