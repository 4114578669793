export const Share: React.FC<{
  color?: string
  width?: string | number
  height: string | number
  className?: string
}> = ({ color, width, height, className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={className}
      viewBox="0 0 30 30"
      fill="none"
    >
      <path
        d="M27.3897 13.0062L18.3897 4.006C18.2953 3.91172 18.1751 3.84753 18.0442 3.82153C17.9134 3.79554 17.7777 3.8089 17.6545 3.85994C17.5312 3.91098 17.4258 3.99741 17.3516 4.10831C17.2775 4.2192 17.2378 4.34959 17.2377 4.48301V9.2306C11.1087 9.63448 3.69378 15.4182 2.84553 22.8051C2.81798 23.0403 2.86536 23.2782 2.98092 23.4849C3.09648 23.6917 3.27433 23.8566 3.48913 23.9564C3.70392 24.0561 3.94471 24.0855 4.1772 24.0404C4.40968 23.9953 4.622 23.8779 4.78391 23.7051C6.05066 22.354 10.6002 18.0508 17.2377 17.7729V22.4833C17.2378 22.6168 17.2775 22.7472 17.3516 22.858C17.4258 22.9689 17.5312 23.0554 17.6545 23.1064C17.7777 23.1575 17.9134 23.1708 18.0442 23.1448C18.1751 23.1188 18.2953 23.0546 18.3897 22.9604L27.3897 13.9602C27.5161 13.8336 27.5871 13.6621 27.5871 13.4832C27.5871 13.3043 27.5161 13.1327 27.3897 13.0062Z"
        fill={color}
      />
    </svg>
  )
}
