import { useContext } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import cn from "classnames"
import styles from "./MenuIcon.module.scss"
import { UserContext } from "../../../context/UserContext"
import { Home } from "../../icons/Home"
import { Trending } from "../../icons/Trending"
import { Store } from "../../icons/Store"
import { AddMeme } from "../../icons/AddMeme"
import { Checkout } from "../../icons/Checkout"
import { Notifications } from "../../icons/Notifications"
import { Avatar } from "../../icons/Avatar"

export type MenuIconType =
  | "Home"
  | "Store"
  | "Trending"
  | "AddMeme"
  | "Checkout"
  | "Notifications"
  | "Avatar"

export type MenuIconComponentProps = {
  onClick: React.MouseEventHandler<HTMLDivElement> | undefined
  type: MenuIconType
  className?: string
  label?: string | boolean
  imageUrl?: string
}

export type MenuIconProps = {
  onClick?: MenuIconComponentProps["onClick"]
  className?: MenuIconComponentProps["className"]
  label?: string | boolean
  imageUrl?: string
}

export type MenuIconsRecord = Record<
  MenuIconType,
  {
    Component: React.FC<any>
    label: string
    path?: string
    unauthenticatedPath?: string
  }
>

const icons: MenuIconsRecord = {
  Home: {
    Component: Home,
    label: "Home",
    path: "/",
  },
  Store: {
    Component: Store,
    label: "Store",
    // path: "/store",
  },
  Trending: {
    Component: Trending,
    label: "Trending",
    path: "/trending",
  },
  Checkout: {
    Component: Checkout,
    label: "Checkout",
    // path: "/checkout",
  },
  AddMeme: {
    Component: AddMeme,
    label: "Add Meme",
    path: "/upload",
    unauthenticatedPath: "/login",
  },
  Notifications: {
    Component: Notifications,
    label: "Notifications",
  },
  Avatar: {
    Component: Avatar,
    label: "Avatar",
    path: "/profile",
  },
}

const MenuIconComponent: React.FC<MenuIconComponentProps> = props => {
  const { onClick, type, className, label, imageUrl } = props
  const { user } = useContext(UserContext)
  const location = useLocation()
  const navigate = useNavigate()
  const icon = icons[type]
  const IconComponent = icon.Component

  const isCurrentPath = (path?: string) => location.pathname === path
  const iconClasses = (path?: string) =>
    cn(styles.icon, isCurrentPath(path) ? styles.currentItem : "")
  const labelClasses = (path?: string) =>
    cn(styles.label, isCurrentPath(path) ? styles.currentItem : "")

  const handleOnClick: MenuIconComponentProps["onClick"] = e => {
    if (onClick) {
      onClick(e)
      return
    }

    if (!icon.path) return

    const navigateToPath =
      icon.unauthenticatedPath && !user ? icon.unauthenticatedPath : icon.path

    navigate(navigateToPath)
  }

  return (
    <div className={cn(styles.item, className)} onClick={handleOnClick}>
      <IconComponent
        color={isCurrentPath(icon.path) ? "#FFFFFF" : "#A1A1A1"}
        height={32}
        width={32}
        className={iconClasses(icon.path)}
        imageUrl={imageUrl}
      />
      {label !== false && (
        <div className={labelClasses(icon.path)}>{label || icon.label}</div>
      )}
    </div>
  )
}

const MenuIconTypes = Object.keys(icons) as Array<MenuIconType>

export const MenuIcon: Record<
  MenuIconType,
  React.FC<MenuIconProps>
> = MenuIconTypes.reduce((acc, type: MenuIconType) => {
  const WrappedComponent: React.FC<MenuIconProps> = props => {
    return (
      <MenuIconComponent
        className={props.className}
        type={type}
        onClick={props.onClick}
        label={props.label}
        imageUrl={props.imageUrl}
      />
    )
  }

  return {
    ...acc,
    [type]: WrappedComponent,
  }
}, {} as Record<MenuIconType, React.FC<MenuIconProps>>)
