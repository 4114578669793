import { useEffect, useState } from "react"
import AvatarPlaceholder from "../../assets/avatar_placeholder.png"
import { Link } from "react-router-dom"
import { BaseLayout } from "../../components/layouts/BaseLayout/BaseLayout"

import styles from "./Profile.module.scss"
import { Meme } from "../../types/meme"
import axios from "axios"
import { Button } from "../../components/common/Button/Button"
import { useTitle } from "../../hooks/useTitle"

export const ProfilePage: React.FC = () => {
  useTitle("Profile")
  const [memes, setMemes] = useState<Meme[]>([])
  const isPersonalProfile = false
  const apiUrl = process.env.REACT_APP_API_URL

  useEffect(() => {
    const fetchMemes = async () => {
      try {
        const response = await axios.get(
          `${apiUrl}/images?code=czrxZH70pKk9j6dkup6o5fFb7_h5IDcoZtg5mpnpyIwbAzFus7SVuQ==`,
        )
        setMemes(response.data)
      } catch (error) {
        console.log(error)
      }
    }

    fetchMemes()
  }, [apiUrl])

  return (
    <BaseLayout>
      <div className={styles.container}>
        <div className={styles.profile}>
          <div className={styles.userDetails}>
            <img
              className={styles.avatar}
              src={AvatarPlaceholder}
              alt="Avatar"
            />
            <div className={styles.userFollow}>
              <div className={styles.userName}>User's name</div>
              {isPersonalProfile && (
                <Button
                  className={styles.followBtn}
                  text="edit"
                  type="default"
                  htmlType="submit"
                />
              )}
              {!isPersonalProfile && (
                <Button
                  className={styles.followBtn}
                  text="follow"
                  type="primary"
                  htmlType="submit"
                />
              )}
            </div>
            <span className={styles.bio}>
              A users presentation, probably not too much text; no essays here.
            </span>
          </div>
          <div className={styles.stats}>
            <div className={styles.stat}>
              <div className={styles.statTitle}>Memes</div>
              <div className={styles.statValue}>5</div>
            </div>
            <div className={styles.verticalRule}></div>
            <div className={styles.stat}>
              <div className={styles.statTitle}>Followers</div>
              <div className={styles.statValue}>5</div>
            </div>
            <div className={styles.verticalRule}></div>
            <div className={styles.stat}>
              <div className={styles.statTitle}>Follows</div>
              <div className={styles.statValue}>5</div>
            </div>
          </div>
          <div className={styles.memes}>
            {memes.map(meme => (
              <div className={styles.meme}>
                <div className={styles.imageWrapper}>
                  <Link to={`/gallery/${meme.uid}`}>
                    <img
                      className={styles.image}
                      src={meme.image}
                      alt={meme.title}
                    />
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </BaseLayout>
  )
}
