import { useContext, useEffect } from "react"
import { MemeCard } from "../../components/common/MemeCard/MemeCard"
import { BaseLayout } from "../../components/layouts/BaseLayout/BaseLayout"
import styles from "./Feed.module.scss"
import MemeCardSkeleton from "../../components/common/MemeCardSkeleton/MemeCardSkeleton"
import { ContentContext } from "../../context/ContentContext"
import { useTitle } from "../../hooks/useTitle"

export type FeedPageProps = {
  store: "memes" | "trendingMemes"
}

export const FeedPage: React.FC<FeedPageProps> = props => {
  const store = props.store || "memes"
  const content = useContext(ContentContext)
  const memes = content[store]
  useTitle(store === "memes" ? "MeMa" : "Trending")

  useEffect(() => {
    if (store === "memes") {
      content.refreshMemes()
    } else if (store === "trendingMemes") {
      content.refreshTrendingMemes()
    }
  }, [store, content])

  return (
    <BaseLayout>
      <div className={styles.feed}>
        {memes.length === 0 && <MemeCardSkeleton cards={3} />}
        {memes.map(meme => (
          <div className={styles.card} key={meme.uid}>
            <MemeCard meme={meme} />
          </div>
        ))}
      </div>
    </BaseLayout>
  )
}
