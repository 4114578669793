import axios from "axios"
import { Token } from "./token"
import { firebase } from "./firebase"
import { API_URL } from "../constants"
import { Meme } from "../types/meme"

class Api {
  token: string | null

  constructor() {
    this.token = Token.get()
  }

  setToken(token: string | null) {
    this.token = token
  }

  async getAuthHeaders() {
    const token = this.token || Token.get() || (await firebase.getToken())
    this.token = token
    Token.set(token)

    return {
      Authorization: `Bearer ${token}`,
    }
  }

  async fetchMemes() {
    const headers = await this.getAuthHeaders()

    return axios.get(
      `${API_URL}/images?code=czrxZH70pKk9j6dkup6o5fFb7_h5IDcoZtg5mpnpyIwbAzFus7SVuQ==`,
      { headers },
    )
  }

  async fetchMeme(uid: Meme["uid"]) {
    const headers = await this.getAuthHeaders()

    return axios.get(
      `${API_URL}/image/${uid}?code=6zMDh54WZB9lNsWb88msMAmT4dPfjpr0p1mcks9CcMYPAzFuNiPnrA==`,
      { headers },
    )
  }

  async fetchTrendingMemes() {
    const headers = await this.getAuthHeaders()

    return axios.get(
      `${API_URL}/images/mostliked?code=LEu4psjgD-rD3sTS6mQTcPnbf-ejB5Yk06SxrM-kMv_hAzFu3vyx_Q==`,
      { headers },
    )
  }

  async likeMeme(uid: Meme["uid"], action: "like" | "dislike") {
    const headers = await this.getAuthHeaders()

    return axios.post(`${API_URL}/image/${uid}/${action}`, "", { headers })
  }
}

export const api = new Api()
