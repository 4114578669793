import { useState } from "react"
import { Search } from "../../icons/Search"
import styles from "./SearchInput.module.scss"
import { Dictate } from "../../icons/Dictate"

export type SearchInputProps = {
  onSearch?: (searchString: string) => void
  onDictate?: (
    setSearchString: React.Dispatch<React.SetStateAction<string>>,
  ) => void
}

export const SearchInput: React.FC<SearchInputProps> = props => {
  const { onSearch, onDictate } = props
  const [searchString, setSearchString] = useState("")

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    setSearchString(e.target.value)
  }

  const handleKeyDown: React.KeyboardEventHandler<HTMLInputElement> = e => {
    if (e.code !== "Enter") return
    if (onSearch) {
      onSearch(searchString)
    }
    setSearchString("")
  }

  const handleDictate = () => {
    if (onDictate) {
      onDictate(setSearchString)
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.searchIconContainer}>
        <Search
          width={15}
          height={15}
          color={searchString ? "#ffffff" : "#757575"}
        />
      </div>
      <div className={styles.dictateIconContainer} onClick={handleDictate}>
        <Dictate width={19} height={19} color="#C7C7CC" />
      </div>
      <input
        type="text"
        className={styles.input}
        placeholder="Search"
        value={searchString}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
      />
    </div>
  )
}
