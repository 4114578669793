export const Trending: React.FC<{
  color?: string
  width?: string
  height: string
  className?: string
}> = ({ color, width, height, className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={className}
      viewBox="0 0 32 32"
      fill="none"
    >
      <path
        d="M8.85 29H4.3C3.585 29 3 28.375 3 27.6111V13.7222C3 12.9583 3.585 12.3333 4.3 12.3333H8.85C9.565 12.3333 10.15 12.9583 10.15 13.7222V27.6111C10.15 28.375 9.565 29 8.85 29ZM18.275 4H13.725C13.01 4 12.425 4.625 12.425 5.38889V27.6111C12.425 28.375 13.01 29 13.725 29H18.275C18.99 29 19.575 28.375 19.575 27.6111V5.38889C19.575 4.625 18.99 4 18.275 4ZM27.7 15.1111H23.15C22.435 15.1111 21.85 15.7361 21.85 16.5V27.6111C21.85 28.375 22.435 29 23.15 29H27.7C28.415 29 29 28.375 29 27.6111V16.5C29 15.7361 28.415 15.1111 27.7 15.1111Z"
        fill={color}
      />
    </svg>
  )
}
