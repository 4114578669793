export const Logo: React.FC<{
  color?: string
  width?: string
  height: string
  className?: string
}> = ({ color, width, height, className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 382 328.82"
      fill={color}
      width={width}
      height={height}
      className={className}
    >
      <path
        d="M70.34,134.18c.59-3.05,1.19-6.1,1.78-9.14q7.3-38,14.6-76Q91,26.61,95.28,4.12c.29-1.61.9-2,2.48-2q94.69.06,189.41,0a2.69,2.69,0,0,1,2.7,1.57q46.31,80.07,92.73,160.06a5,5,0,0,1,0,5.75q-44,75.78-87.82,151.63c-1.57,2.7-3.1,5.43-4.53,8.2a2.63,2.63,0,0,1-2.72,1.6q-35.62-.07-71.24,0-58.9,0-117.8.05a3.87,3.87,0,0,1-4-2.26q-28.22-49.09-56.6-98.11Q20.13,199.75,2.29,169a4.52,4.52,0,0,1,0-5.11q23.85-41.31,47.58-82.71a3.29,3.29,0,0,1,3.85-1.89c6.33.92,5.3.1,6.5,6.06,3.16,15.79,6.21,31.61,9.31,47.41.1.48.26.94.39,1.41Zm117.85,32.46V13.3c0-.62,0-1.23,0-1.84.12-1.64-.55-2.29-2.25-2.29q-39.48.06-79,0c-2.16,0-2.59.4-2.6,2.6,0,3.92.06,7.85.06,11.77q0,42.17,0,84.32c0,10.43-.06,20.86-.06,31.29,0,2.58.46,3,3,3l31.67,0h21.53c2.34,0,2.47.17,2.38,2.56,0,1,0,2,0,2.94.12,2.82,0,3-2.76,3H107.53c-3.08,0-3.1,0-3.1,3.06V320.79c0,3.18.08,3.26,3.35,3.25l77.13,0c3.26,0,3.28,0,3.28-3.28Zm8.93-132.07-.6.11V321.05c0,2.9.06,2.95,3,2.95h79.34c2.8,0,2.83,0,2.82-2.89,0-7.79-.06-15.58-.07-23.37q0-36.36-.05-72.71l-.09-159.41V41.87c0-.68-.06-1.36-.09-2l-.48,0c-.29.85-.62,1.69-.87,2.55q-16.8,57.87-33.56,115.74c-.52,1.78-1.29,2.81-3.34,2.47a10.2,10.2,0,0,0-3.12,0c-2,.29-2.95-.57-3.56-2.49q-18.25-57.6-36.63-115.18C199,40.1,198,37.34,197.12,34.57ZM53.37,93.72l-.46-.12c-.63,1-1.28,2.05-1.89,3.09q-19.17,33.19-38.38,66.36a6,6,0,0,0,0,6.92Q36.85,211.6,60.9,253.33q16.8,29.05,33.59,58.1c.33.57.76,1.09,1.41,2V54.37a4.42,4.42,0,0,0-1,2.14c-1.65,8.1-3.27,16.2-4.93,24.3q-7.64,37.27-15.3,74.52c-1.25,6,0,5.28-6.41,5-1.6-.08-2.11-.83-2.37-2.18Q61.83,137,57.73,115.89C56.3,108.49,54.82,101.11,53.37,93.72ZM290,311.2l.51.17c.43-.71.89-1.42,1.3-2.14Q307.74,281.6,323.65,254q24.45-42.45,48.93-84.88a4.32,4.32,0,0,0,0-4.93c-2.06-3.4-4.05-6.87-5.79-10.44-1.16-2.37-2.73-3.23-5.34-3.22-22.82.09-45.64.07-68.46.09-3,0-3,0-3,3V311.2ZM280.28,9.43H197.85q21.69,67.68,43.25,135l.62,0Q261,77,280.28,9.43ZM360,142.12,290.6,21.86l-.6.21V138.89c0,.49-.17,1.08,0,1.45a4.59,4.59,0,0,0,1.55,1.71,4.5,4.5,0,0,0,2,.11l64.06,0Z"
        transform="translate(-1.5 -2.09)"
      />
    </svg>
  )
}
