import React from "react"
import { Link, useNavigate } from "react-router-dom"
import { firebase } from "../../../lib/firebase"
import { Button } from "../../common/Button/Button"
import { Input } from "../../common/Input/Input"
import styles from "./Signup.module.scss"
import { useForm } from "react-hook-form"

interface RegisterFormFields {
  username: string
  email: string
  password: string
}

export const SignupForm: React.FC = () => {
  const navigate = useNavigate()
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<RegisterFormFields>({
    mode: "onChange",
  })

  const submitHandler = async (
    data: RegisterFormFields,
    event?: React.BaseSyntheticEvent,
  ) => {
    event?.preventDefault()

    try {
      await firebase.createUser({
        userName: data.username,
        email: data.email,
        password: data.password,
      })
      navigate("/")
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <form className={styles.form} onSubmit={handleSubmit(submitHandler)}>
      <label className={styles.greeting}>Register</label>
      <label className={styles.field}>
        <Input
          type="text"
          label="Username"
          placeholder="Enter your username"
          {...register("username", {
            required: "Username is required",
            minLength: {
              value: 4,
              message: "Username must be at least 4 characters",
            },
          })}
          error={errors.username?.message}
        />
      </label>
      <label className={styles.field}>
        <Input
          type="text"
          label="Email"
          placeholder="Enter your email"
          {...register("email", {
            required: "Email is required",
            pattern: {
              value: /^[^@]+@[^@]+\.[a-zA-Z]{2,}$/,
              message: "Invalid email format",
            },
          })}
          error={errors.email?.message}
        />
      </label>
      <label className={styles.field}>
        <Input
          type="password"
          label="Password"
          placeholder="Enter your password"
          {...register("password", {
            required: "Password is required",
            minLength: {
              value: 6,
              message: "Password must be at least 6 characters",
            },
          })}
          error={errors.password?.message}
        />
      </label>
      <div className={styles.buttonDiv}>
        <Button
          className={styles.button}
          text="Cancel"
          type="secondary"
          onClick={() => (window.location.href = "/")}
        />
        <Button
          className={styles.button}
          text="Register"
          type="primary"
          htmlType="submit"
        />
      </div>
      <p className={styles.note}>
        Already have an account? Please{" "}
        <Link className={styles.link} to="/login">
          log in
        </Link>
        .
      </p>
    </form>
  )
}
