export const Search: React.FC<{
  color?: string
  width?: string | number
  height: string | number
  className?: string
}> = ({ color, width, height, className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={className}
      viewBox="0 0 15 15"
      fill="none"
    >
      <path
        d="M14 14L9.66667 9.66667M11.1111 6.05556C11.1111 8.84766 8.84766 11.1111 6.05556 11.1111C3.26345 11.1111 1 8.84766 1 6.05556C1 3.26345 3.26345 1 6.05556 1C8.84766 1 11.1111 3.26345 11.1111 6.05556Z"
        stroke={color}
        strokeWidth="1.44444"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
