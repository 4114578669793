import humanizeDuration from "humanize-duration"

export const duration = humanizeDuration.humanizer({
  language: "shortEn",
  largest: 1,
  round: true,
  spacer: "",
  units: ["y", "mo", "w", "d", "h", "m", "s"],
  languages: {
    shortEn: {
      y: () => "y",
      mo: () => "mo",
      w: () => "w",
      d: () => "d",
      h: () => "h",
      m: () => "m",
      s: () => "s",
    },
  },
})

export const durationFromTimestamp = (timestamp: string | Date) => {
  const nowUnix = Date.now()
  const timestampUnix = new Date(timestamp).getTime()
  const durationMs = nowUnix - timestampUnix
  return duration(durationMs)
}
