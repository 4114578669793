export const Checkout: React.FC<{
  width?: string
  height: string
  color?: string
  className?: string
}> = ({ width, height, className, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={className}
      viewBox="0 0 32 32"
      fill="none"
    >
      <path
        d="M29.9638 9.2675L26.7588 20.8025C26.5825 21.4326 26.2057 21.9881 25.6852 22.3846C25.1648 22.7812 24.5293 22.9973 23.875 23H11.52C10.8638 22.9997 10.2257 22.7848 9.703 22.388C9.18031 21.9913 8.80174 21.4345 8.625 20.8025L4.24 5H2C1.73478 5 1.48043 4.89464 1.29289 4.70711C1.10536 4.51957 1 4.26522 1 4C1 3.73478 1.10536 3.48043 1.29289 3.29289C1.48043 3.10536 1.73478 3 2 3H5C5.21863 2.99996 5.43124 3.07156 5.6053 3.20386C5.77936 3.33615 5.90527 3.52184 5.96375 3.7325L7.14875 8H29C29.1542 7.99997 29.3063 8.03558 29.4444 8.10406C29.5825 8.17254 29.7029 8.27202 29.7962 8.39474C29.8895 8.51746 29.9532 8.66009 29.9823 8.81149C30.0113 8.96289 30.005 9.11895 29.9638 9.2675ZM11 25C10.6044 25 10.2178 25.1173 9.88886 25.3371C9.55996 25.5568 9.30362 25.8692 9.15224 26.2346C9.00087 26.6001 8.96126 27.0022 9.03843 27.3902C9.1156 27.7781 9.30608 28.1345 9.58579 28.4142C9.86549 28.6939 10.2219 28.8844 10.6098 28.9616C10.9978 29.0387 11.3999 28.9991 11.7654 28.8478C12.1308 28.6964 12.4432 28.44 12.6629 28.1111C12.8827 27.7822 13 27.3956 13 27C13 26.4696 12.7893 25.9609 12.4142 25.5858C12.0391 25.2107 11.5304 25 11 25ZM24 25C23.6044 25 23.2178 25.1173 22.8889 25.3371C22.56 25.5568 22.3036 25.8692 22.1522 26.2346C22.0009 26.6001 21.9613 27.0022 22.0384 27.3902C22.1156 27.7781 22.3061 28.1345 22.5858 28.4142C22.8655 28.6939 23.2219 28.8844 23.6098 28.9616C23.9978 29.0387 24.3999 28.9991 24.7654 28.8478C25.1308 28.6964 25.4432 28.44 25.6629 28.1111C25.8827 27.7822 26 27.3956 26 27C26 26.4696 25.7893 25.9609 25.4142 25.5858C25.0391 25.2107 24.5304 25 24 25Z"
        fill={color}
      />
    </svg>
  )
}
