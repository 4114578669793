import cn from "classnames"
import styles from "./Menu.module.scss"
import { MenuIcon } from "../MenuIcon/MenuIcon"

export const Menu: React.FC = () => {
  return (
    <div className={cn(styles.wrapper)}>
      <MenuIcon.Home />
      <MenuIcon.Trending />
      <MenuIcon.Store />
      <MenuIcon.AddMeme className={styles.addMeme} />
    </div>
  )
}
