import { PropsWithChildren } from "react"
import { Menu } from "../../common/Menu/Menu"
import { TopBar } from "../../common/TopBar/TopBar"
import styles from "./BaseLayout.module.scss"

export const BaseLayout: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <div className={styles.pageWrapper}>
      <div className={styles.menuSection}>
        <TopBar />
        <Menu />
      </div>
      <div className={styles.pageContent}>{children}</div>
    </div>
  )
}
