let _TOKEN: string | null = null

export const Token = {
  set: (token: string | null) => {
    _TOKEN = token
  },
  get: () => {
    return _TOKEN ? String(_TOKEN) : null
  },
}
